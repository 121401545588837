import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["uploaded", "thumbnail", "preview", "beforeupload", "afterupload", "background", "thumbnaildiv"]

  initialize(){
    // remove margin bottom set by default input_class config in simple_form
    this.uploadedTarget.classList.remove("mb-4");
  }
  
  renderThumbnail(){
    this.previewTarget.classList.add("hidden");
    this.beforeuploadTarget.classList.add("hidden");
    this.uploadedTarget.classList.remove("cursor-pointer");
    this.afteruploadTarget.classList.remove("hidden");
    this.backgroundTarget.classList.add("bg-navy-900");
    // https://www.sledgeworx.io/previewing-image-uploads-with-stimulus-js/
    var input = this.uploadedTarget
    var output = this.thumbnailTarget
    if (input.files && input.files[0]) {
        var reader = new FileReader();
  
        reader.onload = function () {
         output.src = reader.result
       }
  
       reader.readAsDataURL(input.files[0]);
      }
    this.thumbnaildivTarget.classList.remove("hidden");
    this.thumbnailTarget.classList.remove("hidden");
    }
  
  removeThumbnail(){
    this.thumbnailTarget.classList.add("hidden");
    this.thumbnaildivTarget.classList.add("hidden");
    this.uploadedTarget.value = "";
    this.uploadedTarget.classList.add("cursor-pointer");
    this.afteruploadTarget.classList.add("hidden");
    this.backgroundTarget.classList.remove("bg-navy-900");
    this.previewTarget.classList.remove("hidden");
    this.beforeuploadTarget.classList.remove("hidden");
    this.thumbnailTarget.src = "";
  }
}