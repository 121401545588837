// Heavily inspired by:
// * https://github.com/excid3/tailwindcss-stimulus-components/blob/master/src/dropdown.js

// Example usage:
// <div class="relative" data-controller="dropdown">
//  <div data-action="click->dropdown#toggle click@window->dropdown#hide" role="button" class="inline-block select-none">
//    Open Dropdown
//  </div>
//  <div data-target="dropdown.menu" class="absolute pin-r mt-2 hidden">
//    <div class="bg-white shadow rounded border overflow-hidden">
//      Content
//    </div>
//  </div>
// </div>

import { Controller } from "stimulus"

const ESCAPE_KEY_CODE = 27

export default class extends Controller {
  static targets = ["menu"]

  connect() {
    this.toggleClass = this.data.get("class") || "hidden"
  }

  toggle() {
    this.menuTarget.classList.toggle(this.toggleClass)
  }

  addToggleClassIfNotPresent() {
    if (!this.menuTarget.classList.contains(this.toggleClass)) {
      this.menuTarget.classList.add(this.toggleClass)
    }
  }

  hide(event) {
    if (this.element.contains(event.target) === false) {
      this.addToggleClassIfNotPresent()
    }
  }

  hideWithKeyboard(event) {
    if (event.keyCode === ESCAPE_KEY_CODE) {
      this.addToggleClassIfNotPresent()
    }
  }
}
