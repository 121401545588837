import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["preconstructionField", "estimatedValueField", "valueAsterisk", 
                    "startDateAsterisk", "endDateAsterisk", "startDateField", "endDateField"]

  connect(){

  }

  updateForm(){
    if (this.preconstructionFieldTarget.checked){
        this.estimatedValueFieldTarget.removeAttribute("required");
        this.valueAsteriskTarget.classList.add("hidden");
        this.startDateFieldTarget.removeAttribute("required");
        this.startDateAsteriskTarget.classList.add("hidden");
        this.endDateFieldTarget.removeAttribute("required");
        this.endDateAsteriskTarget.classList.add("hidden");
    }
    else {
        this.estimatedValueFieldTarget.setAttribute("required", "true");
        this.valueAsteriskTarget.classList.remove("hidden");
        this.startDateFieldTarget.setAttribute("required", "true");
        this.startDateAsteriskTarget.classList.remove("hidden");
        this.endDateFieldTarget.setAttribute("required", "true");
        this.endDateAsteriskTarget.classList.remove("hidden");
    }
  }

}