import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']

  connect() {
    // The class toggled on the container
    this.toggleClass = this.data.get('class') || 'hidden';
    // Let user close the modal by clicking on the background
    this.allowBackgroundClose = (this.data.get('allowBackgroundClose') || 'true') === 'true';
    // Prevent default action of the clicked element (form submission for example) when opening the modal
    this.preventDefaultActionOpening = (this.data.get('preventDefaultActionOpening') || 'true') === 'true';
    // Prevent default action of the clicked element (form submission for example) when closing the modal
    this.preventDefaultActionClosing = (this.data.get('preventDefaultActionClosing') || 'true') === 'true';
  }

  disconnect() {
    this.close();
  }

  open(e) {
    if (this.preventDefaultActionOpening) {
      e.preventDefault();
    }
    // Unhide the modal
    this.containerTarget.classList.remove(this.toggleClass);
    // Insert the background
    document.body.insertAdjacentHTML('afterbegin', this._backgroundHTML());
    this.background = document.querySelector('#background-during-modal');
  }

  _backgroundHTML() {
    return `<div id="background-during-modal" class="fixed top-0 left-0 w-full h-full z-40" style="background-color: rgba(0, 0, 0, 0.35);"></div>`;
  }

  close(e) {
    if (e && this.preventDefaultActionClosing) {
      e.preventDefault();
    }
    // Hide the modal
    this.containerTarget.classList.add(this.toggleClass);
    // Remove the background
    if (this.background) {
      this.background.remove() 
    }
  }

  closeBackground(e) {
    if (this.allowBackgroundClose && e.target === this.containerTarget) {
      this.close(e);
    }
  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27 && !this.containerTarget.classList.contains(this.toggleClass)) {
      this.close(e);
    }
  }

}