import { Controller } from "stimulus"
import request from "superagent"

const googleMapsApiKey = process.env.GOOGLE_MAPS_API_KEY
export default class extends Controller {
    static targets = ["addressField", "locationAddressOneField", "locationAddressTwoField", "locationCityField", "locationStateField", "usaStateField", "locationCountryField", "locationZipField", "timezone"]

    connect() {
        if (typeof(google) != "undefined") {
            this.initMap()
          }
    }

    initMap() {
      this.autocomplete = new google.maps.places.Autocomplete(this.addressFieldTarget)
      this.autocomplete.setFields(['address_components', 'name', 'place_id'])
      this.autocomplete.addListener('place_changed', this.placeChangedSingleField.bind(this))
    }

    initMapForUS() {
      if (this.locationCountryFieldTarget.value == "US"){
          this.autocomplete = new google.maps.places.Autocomplete(this.locationAddressOneFieldTarget)
          this.autocomplete.setTypes(['address'])
          this.autocomplete.setComponentRestrictions({'country': ["us"]})
          this.autocomplete.setFields(['address_components', 'formatted_address', 'place_id'])
          this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
      }
  }

    changeStateOptions(){
        if (this.locationCountryFieldTarget.value == "US" && this.usaStateFieldTarget.classList.contains("hidden")) {
          this.locationStateFieldTarget.removeAttribute("required");
          this.locationStateFieldTarget.setAttribute("disabled", "true");
          this.locationStateFieldTarget.classList.add("hidden");
          this.usaStateFieldTarget.classList.remove("hidden");
          this.usaStateFieldTarget.setAttribute("required", "true");
          this.usaStateFieldTarget.removeAttribute("disabled");
        }
        if (this.locationCountryFieldTarget.value != "US" && this.locationStateFieldTarget.classList.contains("hidden")){
          this.locationStateFieldTarget.classList.remove("hidden");
          this.locationStateFieldTarget.setAttribute("required", "true");
          this.locationStateFieldTarget.removeAttribute("disabled");
          this.usaStateFieldTarget.removeAttribute("required");
          this.usaStateFieldTarget.setAttribute("disabled", "true");
          this.usaStateFieldTarget.classList.add("hidden");
        }
      }

    placeChangedSingleField(){
      let place = this.autocomplete.getPlace()
      this.placeConfirmed(place.place_id)
    }

    placeChanged(){
        let place = this.autocomplete.getPlace()
        this.addressFieldTarget.value = place.formatted_address
        let location_address_one_beginning = ""
        for (const component of place.address_components) {
            // @ts-ignore remove once typings fixed
            const componentType = component.types[0];
            /* if google ever changes the ordering of address_components in the future, it would
            not degrade customer experience as users can still edit the address fields manually */
            switch (componentType) {

              case "street_number": {
                location_address_one_beginning = component.long_name;
                break;
              }
      
              case "route": {
                location_address_one_beginning = location_address_one_beginning.concat(" ");
                this.locationAddressOneFieldTarget.value = location_address_one_beginning.concat(component.long_name);
                break;
              }

              case "locality": {
                this.locationCityFieldTarget.value = component.long_name;
                break;
              }

              case "administrative_area_level_1": {
                this.usaStateFieldTarget.value = component.long_name;
                break;
              }

              case "postal_code": {
                this.locationZipFieldTarget.value = component.long_name;
                break;
              }

            }
        }
        this.placeConfirmed(place.place_id)
    }

    placeConfirmed(place_id){
        this.geocoder = new google.maps.Geocoder();
        var loc=[];
        loc = this.geocoder.geocode( { 'placeId': place_id}, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              loc[0]=results[0].geometry.location.lat();
              loc[1]=results[0].geometry.location.lng();
              this.fetchTimezones(loc[0],loc[1]);
            } else {
              if (typeof Honeybadger !== 'undefined') 
                {  
                    Honeybadger.notify("Geocode was not successful for the following reason: " + status);
                }
            }
          });    
    }

    fetchTimezones(lat, lng){
        return request
        .get("https://maps.googleapis.com/maps/api/timezone/json")
        .query({
        location: `${lat},${lng}`,
        key: googleMapsApiKey,
        timestamp: Math.round(new Date().getTime() / 1000).toString(), // https://stackoverflow.com/a/19887055
        })
        .then((result) => {
        if (result.ok) {
            this.timezoneTarget.value = result.body.timeZoneId;
        }
        else{
            throw result
        }
        }).catch((err)=>{ 
            if (typeof Honeybadger !== 'undefined') 
            {
                Honeybadger.notify(err);
            }
        }); 
    }
}